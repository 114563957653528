
































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { DecoContestVotableEntry } from '@/game/infos/dataServerPackets';
import userx from '@/store/modules/userx';
import fb from '@/store/sf-firestore';
import DecoEntryForVote from './DecoEntryForVote.vue';

@Component({
  components: {
    DecoEntryForVote,
  },
})
export default class DecoVote extends Vue {
  public entries: DecoContestVotableEntry[] = Array.from({ length: 10 }).fill({
    uid: '',
    decoSet: '',
    skin: null,
    fishType: 0,
    numLikes: 0,
    numDislikes: 0,
    liked: false,
    disliked: false,
    reported: false,
  }) as DecoContestVotableEntry[];

  public entryContainerWidth = 1600;

  public voteEnded = false;

  public mounted() {
    this.loadEntries();
    if (this.voteEnded) {
      return;
    }
    this.entryContainerWidth = (this.$refs
      .entryContainer as HTMLDivElement).clientWidth;
    window.addEventListener('resize', this.onResize);
  }
  public beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }
  public onResize() {
    this.entryContainerWidth = (this.$refs
      .entryContainer as HTMLDivElement).clientWidth;
  }
  public get numEntries() {
    return this.entryContainerWidth >= 1582
      ? 10
      : this.entryContainerWidth >= 1266
      ? 8
      : this.entryContainerWidth >= 949
      ? 6
      : 4;
  }
  public get culledEntries() {
    return this.entries.slice(0, this.numEntries);
  }

  public async loadEntries() {
    const ct = Date.now();
    if (ct >= 1674921600000) {
      this.voteEnded = true;
      return;
    }
    this.entries = await fb.getDecoEntriesForVote(1, this.numEntries);
  }
}
